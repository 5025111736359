<template>
    <ion-page>
        <ion-content :fullscreen="true">

            <div class="ion-text-center">
                <br/>
                <img alt="MafiaBot" src="/android-chrome-192x192.png" />
                <br/>
            </div>

            <template v-if="errorMessage">
                <pre style="padding: 1rem; border-radius: 0.5rem; background-color: #545454;">
                    {{ errorMessage }}
                </pre>
                <br/>
            </template>

            <ion-card>
                <ion-card-header>
                    <ion-card-title>
                      MafiaBot is an app for conducting "mafia" games easily online with friends.
                      It was designed for Rocket League but can be played with most online
                      multiplayer games that have equal team sizes and private lobbies.
                    </ion-card-title>
                </ion-card-header>

                <ion-card-content>
                    <template v-if="auth.isAuthenticated">
                        <h1>Hi, {{ auth.user.name }}</h1>
                        <br/>
                        <ion-button
                            expand="block"
                            href="/start"
                        >
                            Start Game
                        </ion-button>
                        <br/>
                        <ion-button
                            expand="block"
                            color="tertiary"
                            href="/join"
                        >
                            Join Game
                        </ion-button>
                        <br/>
                        <template v-if="auth.isAdmin">
                            <ion-button
                                expand="block"
                                color="success"
                                href="/admin"
                            >
                                Administration
                            </ion-button>
                            <br/>
                        </template>
                        <br/>
                        <br/>
                        <ion-button
                            expand="block"
                            color="danger"
                            @click="auth.logout()"
                        >
                            Logout
                        </ion-button>
                    </template>
                    <template v-else-if="isAuthenticating">
                        <ion-button
                            expand="block"
                            color="danger"
                            @click="cancelAuth"
                        >
                            Cancel Authentication
                        </ion-button>
                    </template>
                    <template v-else>
                        <ion-button
                            expand="block"
                            color="dark"
                            @click="startAuth('discord')"
                        >
                            Login with Discord
                        </ion-button>
                        <br/>
                        <ion-button
                            expand="block"
                            color="dark"
                            @click="startAuth('facebook')"
                        >
                            Login with Facebook
                        </ion-button>
                        <br/>
                        <ion-button
                            expand="block"
                            color="dark"
                            @click="startAuth('google')"
                        >
                            Login with Google
                        </ion-button>
                    </template>
                </ion-card-content>
            </ion-card>
            <footer-component/>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import {
        IonButton,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardTitle,
        IonContent,
        IonPage,
    } from '@ionic/vue';
    import { Browser } from '@capacitor/browser';
    import { ref } from 'vue';
    import FooterComponent from '@/components/FooterComponent.vue';
    import auth from '@/auth';

    let pollExchangeIntervalId = 0;

    const apiUrl = import.meta.env.VITE_APP_BACKEND_URL,
        errorMessage = ref(''),
        isAuthenticating = ref(false),
        startAuth = async (driver : string) => {
            if(isAuthenticating.value){
                return;
            }

            isAuthenticating.value = true;
            const exchangeToken = auth.generateUUID();
            await Browser.open({ url: `${apiUrl}/auth/${driver}/redirect/${exchangeToken}` })
                .then(() => {
                    pollExchangeIntervalId = setInterval(async () => {
                        await fetch(`${apiUrl}/auth/exchange/${exchangeToken}`)
                            .then(async (response) => {
                                const data = await response.json();
                                if(response.ok && data.success && isAuthenticating.value){
                                    cancelAuth();
                                    await auth.authenticate(data.token, data.user);
                                }
                            });
                    }, 1500);
                })
                .catch(err => errorMessage.value = err);
        },
        cancelAuth = () => {
            clearInterval(pollExchangeIntervalId);
            isAuthenticating.value = false;
        };
</script>

import router from '../router';

const tokenKey = 'authenticated-token',
    userKey = 'authenticated-user';

const auth = {
    get token() : string | null {
        return localStorage.getItem(tokenKey);
    },
    get user(){
        return Object(JSON.parse(localStorage.getItem(userKey) || '{}'));
    },
    get isAuthenticated(){
        return this.token && !!Object.keys(this.user).length
    },
    get isAdmin(){
        return this.isAuthenticated && this.user?.is_admin;
    },
    generateUUID(){
        let d = new Date().getTime();
        const d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16;
            r = (d > 0) ? ((d + r)%16 | 0) : ((d2 + r)%16 | 0);
            d = (d > 0) ? Math.floor(d/16) : Math.floor(d2/16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },
    authenticate: async (token: string, user : object) => {
        localStorage.setItem(tokenKey, token);
        localStorage.setItem(userKey, JSON.stringify(user));
        await router.push('/');
    },
    logout: async () => {
        localStorage.removeItem(userKey);
        localStorage.removeItem(tokenKey);
        await router.push('/');
        window.location.reload();
    },
    fetch(path : string, body : object | null = null, method : string = body ? 'POST' : 'GET'){
        return fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/${path}`, {
            method: method,
            headers: {
                Authorization: `Bearer ${this.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            ...(body ? { body: JSON.stringify(body) } : {}),
        });
    },
    get(path : string){
        return this.fetch(path, null, 'GET');
    },
    post(path : string, body : object | null = null){
        return this.fetch(path, body, 'POST');
    },
    delete(path : string){
        return this.fetch(path, {}, 'DELETE');
    },
};

export default auth;

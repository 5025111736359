import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import auth from '@/auth';
import IndexPage from '@/views/IndexPage.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: IndexPage,
    },
    {
        path: '/reviewer',
        component: () => import('@/views/ReviewerPage.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/start',
        component: () => import('@/views/CreatePage.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/join',
        component: () => import('@/views/JoinPage.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/game/:id',
        component: () => import('@/views/GamePage.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin',
        component: () => import('@/views/admin/AdminPage.vue'),
        meta: {
            admin: true,
        },
    },
    {
        path: '/data-deletion',
        component: () => import('@/views/pages/DataDeletionPage.vue'),
    },
    {
        path: '/privacy',
        component: () => import('@/views/pages/PrivacyPage.vue'),
    },
    {
        path: '/terms-of-service',
        component: () => import('@/views/pages/TermsOfServicePage.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to) => {
    if ((to.meta?.auth && !auth.isAuthenticated) || (to.meta.auth === false && auth.isAuthenticated)) {
        return {
            path: '/',
        };
    }
    if ((to.meta?.admin && !auth.isAdmin)) {
        return {
            path: '/',
        };
    }
});

export default router;

<template>
    <br/>
    <br/>
    <br/>
    <div class="ion-text-center">
        <a href="/privacy">Privacy Policy</a>
        &mdash;
        <a href="/terms-of-service">Terms of Service</a>
        &mdash;
        <a href="/data-deletion">Data Deletion</a>
    </div>
</template>
